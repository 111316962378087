import { Button, Box } from '@material-ui/core';

export default function LinkedinLogin() {
  return (
    <Box>
      <Button
        component="a"
        variant="outlined"
        color="inherit"
        size="large"
        startIcon={<Box component="img" src="/static/icons/linkedin.svg" />}
        href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
          process.env.REACT_APP_LINKEDIN_CLIENT_ID
        }&redirect_uri=${encodeURI(
          process.env.REACT_APP_LINKEDIN_REDIRECT_URL
        )}&state=foobar&scope=openid%20profile%20email`}
      >
        Entrar com o Linkedin
      </Button>
    </Box>
  );
}
