import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import { useSnackbar } from 'notistack5';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { dealerJobsApi } from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import { MIconButton } from '../../components/@material-extend';

export default function NotificationPreferencesPage() {
  const { user } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [receiveNewJobNotifications, setReceiveNewJobNotifications] = useState(true);
  const [receiveUpdateReminders, setReceiveUpdateReminders] = useState(true);
  const [receiveUpdateWhatsApp, setReceiveUpdateWhatsApp] = useState(false);
  const [receiveNewJobNotificationsWhatsApp, setReceiveNewJobNotificationsWhatsApp] = useState(false);

  useEffect(() => {
    fetchNotificationPreferences();
  }, []);

  const fetchNotificationPreferences = async () => {
    try {
      const response = await dealerJobsApi.get(`/candidate/${user.candidate.id}/notification-preferences`);

      const {
        receiveNewJobNotifications,
        receiveUpdateReminders,
        receiveUpdateWhatsApp,
        receiveJobNotificationsWhatsApp
      } = response.data;

      setReceiveNewJobNotifications(receiveNewJobNotifications);
      setReceiveUpdateReminders(receiveUpdateReminders);
      setReceiveUpdateWhatsApp(receiveUpdateWhatsApp);
      setReceiveNewJobNotificationsWhatsApp(receiveJobNotificationsWhatsApp);
    } catch (error) {
      console.error('Erro ao buscar preferências de notificação:', error.message);
    }
  };

  const handleNewJobNotificationsChange = () => {
    setReceiveNewJobNotifications(!receiveNewJobNotifications);
  };

  const handleUpdateRemindersChange = () => {
    setReceiveUpdateReminders(!receiveUpdateReminders);
  };

  const handleUpdateWhatsAppChange = () => {
    setReceiveUpdateWhatsApp(!receiveUpdateWhatsApp);
  };

  const handleUpdateNewJobNotificationsWhatsAppChange = () => {
    setReceiveNewJobNotificationsWhatsApp(!receiveNewJobNotificationsWhatsApp);
  };

  const handleSavePreferences = async () => {
    try {
      await dealerJobsApi.put(`/candidate/${user.candidate.id}/update-new-job-notifications`, {
        newValue: receiveNewJobNotifications
      });

      await dealerJobsApi.put(`/candidate/${user.candidate.id}/update-reminders`, {
        newValue: receiveUpdateReminders
      });

      await dealerJobsApi.put(`/candidate/${user.candidate.id}/receive-update-whatsApp`, {
        newValue: receiveUpdateWhatsApp
      });

      await dealerJobsApi.put(`/candidate/${user.candidate.id}/receive-jobNotifications-whatsApp`, {
        newValue: receiveNewJobNotificationsWhatsApp
      });
      enqueueSnackbar('Atualizado com sucesso!', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      console.error('Erro ao atualizar preferências:', error.message);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100px' }}>
      <Grid item xs={12} md={6}>
        <Card style={{ width: '800px' }}>
          <CardContent style={{ textAlign: 'center' }}>
            <h1 style={{ margin: '0 auto', marginBottom: '20px' }}>Preferências de E-mails e WhatsApp</h1>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Preferências</TableCell>
                    <TableCell>E-mails</TableCell>
                    <TableCell>WhatsApp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Receber lembretes de novas vagas</TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={receiveNewJobNotifications}
                            onChange={handleNewJobNotificationsChange}
                            name="receiveNewJobNotifications"
                            color="primary"
                          />
                        }
                        label=""
                      />
                    </TableCell>
                    {/* <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={receiveNewJobNotificationsWhatsApp}
                            onChange={handleUpdateNewJobNotificationsWhatsAppChange}
                            name="receiveNewJobNotificationsWhatsApp"
                            color="primary"
                          />
                        }
                        label=""
                      />
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell>Receber lembretes de atualizar dados cadastrais</TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={receiveUpdateReminders}
                            onChange={handleUpdateRemindersChange}
                            name="receiveUpdateReminders"
                            color="primary"
                          />
                        }
                        label=""
                      />
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={receiveUpdateWhatsApp}
                            onChange={handleUpdateWhatsAppChange}
                            name="receiveUpdateWhatsApp"
                            color="primary"
                          />
                        }
                        label=""
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Button variant="contained" color="primary" style={{ marginTop: '20px' }} onClick={handleSavePreferences}>
              Salvar Preferências
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
